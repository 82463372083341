<template>
    <div>Esporta Prezzi Tessere</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Prezzi Tessere", route: "/settings/membercard-price/index" },
             { title: "Esporta Prezzi Tessere" }
         ]);
     }
 };
</script>
